<template>
    <layout-login-v2 />
</template>

<script>
import LayoutLoginV2 from "@/layouts/LayoutLoginV2";

export default {
    name: "PageLoginV2",
    components: {
        LayoutLoginV2,
    },
}
</script>

<style scoped>

</style>
